<template>
  <b-overlay :show="loading">
    <schedule-interview-modal
      :is-schedule-interview.sync="toggleInterviewModal"
      @send="options => actionCV('interview', options)"
    />

    <request-application-letter-modal
      :is-toggle-modal.sync="toggleApplicationLetterModal"
      @send="options => actionCV('letter', options)"
    />

    <div v-if="candidate" class="mt-5 w-75">
      <b-row class="mb-2 ml-50">
        <b-button 
          v-if="!disableActionField('letter')"
          class="mr-1"
          :variant="candidate.pending_letter ? 'outline-warning' : 'outline-primary'"
          @click="!candidate.pending_letter ? onToggleApplicationLetterModal() : undoCVAction(getActionId('letter'))"
        >
          <span>
            <feather-icon icon="FileTextIcon" class="d-inline-block mr-50"></feather-icon>
          </span>
          <span class="align-middle text-lg">{{ candidate.pending_letter ? 'Undo Application Letter' : 'Request Application Letter' }}</span>
        </b-button>

        <b-button 
          v-if="!disableActionField('interview')"
          class="mr-1"
          :variant="candidate.pending_interview ? 'outline-warning' : 'outline-primary'"
          @click="!candidate.pending_interview ? onToggleInterviewModal() : undoCVAction(getActionId('interview'))"
        >
          <span>
            <feather-icon icon="CalendarIcon" class="d-inline-block mr-50"></feather-icon>
          </span>
          <span class="align-middle text-lg"></span>
          <span class="align-middle text-lg">{{ candidate.pending_interview ? 'Undo Interview Request' : 'Request Interview' }}</span>
        </b-button>

        <b-button 
          v-if="!disableActionField('shortlist')"
          class="mr-1"
          variant="outline-primary"
          @click="actionCV('shortlist')"
        >
          <span>
            <feather-icon icon="CheckSquareIcon" class="d-inline-block mr-50"></feather-icon>
          </span>
          <span class="align-middle text-lg">{{ disableActionField('shortlist') ? 'Shortlisted' : 'Shortlist' }}</span>
        </b-button>

        <b-button 
          class="mr-1"
          variant="outline-primary"
          @click="printCV"
        >
          <span>
            <feather-icon icon="PrintIcon" class="d-inline-block mr-50"></feather-icon>
          </span>
          <span class="align-middle text-lg">{{ 'Print CV' }}</span>
        </b-button>
      </b-row>
      <div id="cvRoot">
      <b-card title="">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-card-title class="mb-25">
              {{ candidate.name }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ candidate.title }}
            </b-card-text>

            <b-media no-body class="mb-50">
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="PhoneIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-warning">
                  {{ candidate.phone || 'Redacted' }}
                </h6>
                <small>{{ candidate.phone ? 'access granted' : 'request access' }}</small>
              </b-media-body>
            </b-media>

            <b-media no-body class="mb-50">
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="MailIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-warning">
                  {{ candidate.email || 'Redacted' }}
                </h6>
                <small>{{ candidate.email ? 'access granted' : 'request access' }}</small>
              </b-media-body>
            </b-media>
            
            <b-media no-body class="mb-50">
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="MapPinIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ candidate.city }}
                </h6>
                <small>{{ candidate.region }}, {{ candidate.country }}</small>
              </b-media-body>
            </b-media>
          </div>

          <div>
            <b-avatar
              :src="candidate.avatar"
              :text="avatarText(candidate.name)"
              :variant="`light-primary`"
              size="120px"
              rounded
            />
          </div>
        </div>
      </b-card>

      <b-card title="Professional Summary">
        <span class="text-capitalize d-block">{{ candidate.summary }}</span>
        <br />
        <span class="text-capitalize d-block">{{ candidate.why_you }}</span>
      </b-card>

      <b-card title="Work Experience">
        <app-timeline>
          <app-timeline-item v-for="(work_experience, i) in candidate.work_experience" :key="`exp-${i}`" class="">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>
                <span class="text-capitalize d-inline-block">{{ work_experience.job_role }}, </span>
                <span class="text-capitalize d-inline-block ml-50">{{ work_experience.name_of_company }}</span>
              </h6>

              <small class="text-muted">{{ work_experience.employment_type }}</small>
            </div>
            
            <span class="text-capitalize d-block">{{ work_experience.location }}</span>
            <span class="text-capitalize d-inline-block">{{ formatDate(work_experience.date_started, 'MMMM, yyyy') }}</span>
            <span class="text-capitalize d-inline-block mx-50">-</span>
            <span class="text-capitalize d-inline-block mb-1">{{ work_experience.is_current_work ? 'Present' : formatDate(work_experience.date_ended, 'MMMM, yyyy') }}</span>
            <span class="text-capitalize d-block">Responsibilities</span>
            <span class="text-capitalize d-block">{{ work_experience.responsibilities }}</span>
          </app-timeline-item>
        </app-timeline>
      </b-card>

      <b-card title="Education">
        <app-timeline>
          <app-timeline-item v-for="(education_history, i) in candidate.education_history" :key="`exp-${i}`" class="">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>
                <span class="text-capitalize d-inline-block">{{ education_history.program_studied }}, </span>
                <span class="text-capitalize d-inline-block ml-50">{{ education_history.name_of_institution }}</span>
              </h6>

              <small class="text-muted">{{ education_history.type_of_certificate }}</small>
            </div>
            
            <span class="text-capitalize d-inline-block">{{ formatDate(education_history.date_started, 'MMMM, yyyy') }}</span>
            <span class="text-capitalize d-inline-block mx-50">-</span>
            <span class="text-capitalize d-inline-block mb-1">{{ formatDate(education_history.date_ended, 'MMMM, yyyy') }}</span>
          </app-timeline-item>
        </app-timeline>
      </b-card>

      <b-card title="Skills">
        <b-list-group>
          <b-list-group-item v-for="(skill, i) in candidate.skills" :key="`exp-${i}`" class="d-flex">
            <span class="mr-1">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
              />
            </span>

            <span class="text-capitalize">{{ skill.name }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-card>

      <b-card title="Volunteer and Community Involvement">
        <app-timeline>
          <app-timeline-item v-for="(voluntary_service, i) in candidate.voluntary_services" :key="`exp-${i}`" class="">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>
                <span class="text-capitalize d-inline-block">{{ voluntary_service.title }}</span>
              </h6>
            </div>
            
            <span class="text-capitalize d-block">{{ voluntary_service.description }}</span>
          </app-timeline-item>
        </app-timeline>
      </b-card>

      <b-card title="Hobbies and Interests">
        <b-list-group>
          <b-list-group-item v-for="(hobby, i) in candidate.hobbies_and_interests" :key="`exp-${i}`" class="d-flex">
            <span class="mr-1">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
              />
            </span>

            <span class="text-capitalize">{{ hobby.name }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-card>

      <b-card title="References">
        <b-row>
          <b-col v-for="(reference, i) in candidate.references" :key="`exp-${i}`" md="3" class="d-flex">
            <div>
              <h4 class="mb-25">
                {{ reference.full_name }}
              </h4>
              <b-card-text class="mb-2">
                {{ candidate.name_of_institution }}
              </b-card-text>

              <b-media no-body class="mb-50">
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0 text-warning">
                    {{ reference.role }}
                  </h6>
                  <small>role</small>
                </b-media-body>
              </b-media>
              
              <b-media no-body class="mb-50">
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ reference.phone_number }}
                  </h6>
                  <small>phone</small>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
        </b-row>
      </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BListGroup, BListGroupItem,
  BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BButton
} from 'bootstrap-vue'

import { get } from "lodash";

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ScheduleInterviewModal from "@/@core/components/shared/provider/ScheduleInterviewModal.vue";
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue';
import RequestApplicationLetterModal from "@/@core/components/shared/provider/RequestApplicationLetterModal.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BLink,
    BMedia,
    BBadge,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BMediaBody,
    BMediaAside,
    BInputGroup,
    BPagination,
    AppTimeline,
    BListGroup, 
    BListGroupItem,
    AppTimelineItem,
    BInputGroupAppend,
    ContentWithSidebar,
    ScheduleInterviewModal,
    RequestApplicationLetterModal
  },
  data() {
    return {
      loading: false,
      candidate: null,
      toggleInterviewModal: false,
      toggleApplicationLetterModal: false
    }
  },
  computed: {},
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.loading = true; 
        const { id } = this.$route.params 

        const request = await this.useJwt().providerService.fetchCandidate(id);
        const { data } = request.data;

        this.candidate = data
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    printCV() {
      this.$htmlToPaper("cvRoot");
    },
    async actionCV(subject, options = {}) {
      try {
        this.loading = true; 

        const result = await this.$swal({
          title: 'Confirm Request?',
          text: `Do you want to proceed with this ${subject} request?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const payload = { id: this.candidate._id, subject, options }
        await this.useJwt().providerService.actionCV(payload);

        this.fetch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `${subject} request sent successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async undoCVAction(id) {
      try {
        this.loading = true; 

        const result = await this.$swal({
          title: 'Undo Request?',
          text: `Do you want to undo request?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().providerService.undoCVAction(id);
        this.fetch();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `request sent successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    disableActionField(subject){
      const actions = this.getValueFromSource(this.candidate, 'cv_actions', []);
      const hasForSubject = actions.find(action => action.subject === subject);
      if (!hasForSubject) return false;

      if (hasForSubject.status === 'approved' || hasForSubject.status === 'employed'){
        return true
      }

      return false
    },
    hasPendingRequest(subject){
      const actions = this.getValueFromSource(this.candidate, 'cv_actions', []);
      const hasForSubject = actions.find(action => action.subject === subject);
      if (!hasForSubject) return false;

      if (hasForSubject.status === 'pending'){
        return true
      }

      return false
    },
    getActionId(subject){
      const actions = this.getValueFromSource(this.candidate, 'cv_actions', []);
      const action = actions.find(ac => ac.subject === subject);
      return action._id
    },
    onToggleInterviewModal() {
      this.toggleInterviewModal = true;
    },
    onToggleApplicationLetterModal() {
      this.toggleApplicationLetterModal = true;
    }
  },
}
</script>
