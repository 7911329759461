<template>
  <b-modal
    id="is-toggle-modal"
    :visible="isToggleModal"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-toggle-modal', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Request Application Letter
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <validation-provider
              #default="validationContext"
              name="Open Position"
              rules="required"
            >
              <b-form-group
                label="Open Position"
                label-for="name"
              >
                <template #label>
                  <span>Open Position<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="open_position"
                  v-model="open_position"
                  autofocus
                  trim
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Additional Info"
              rules=""
            >
              <b-form-group
                label="Additional Info"
                label-for="name"
              >

                <b-form-textarea
                  id="additional_info"
                  v-model="additional_info"
                  rows="5"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Proceed
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BForm, BFormGroup,
  BFormInvalidFeedback, BButton, BModal, BFormInput, BFormTextarea
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleModal',
    event: 'update:is-toggle-modal',
  },
  props: {
    isToggleModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,

      additional_info: '',
      open_position: '',
    }
  },
  computed: {
    disableForm(){
      return false
    },
  },
  methods: {
    onCreateOrUpdate(){
      const { open_position, additional_info } = this;
      this.$emit('update:is-toggle-modal', false);
      this.$emit("send", { open_position, additional_info });
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      if (this.$refs.modal_form) {
        this.$refs.modal_form.reset()
      }
    }
  }
}
</script>
