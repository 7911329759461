<template>
  <b-modal
    id="is-schedule-interview"
    :visible="isScheduleInterview"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-schedule-interview', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Schedule Interview
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <validation-provider
              #default="validationContext"
              name="Date"
              rules="required"
            >
              <b-form-group
                label="Date"
                label-for="date"
              >
                <template #label>
                  <span>Date<sup style="color: tomato">*</sup></span>
                </template>

                <b-calendar
                  id="date"
                  v-model="interview_date"
                  block
                  :min="new Date()"
                  :disabled="disableForm"
                  :date-disabled-fn="disableWeekends"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Time"
              rules="required"
            >
              <b-form-group
                label="Time"
                label-for="time"
              >
                <template #label>
                  <span>Time<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-timepicker
                  id="time"
                  v-model="interview_time"
                  dropup
                  :disabled="disableForm"
                  placeholder="Choose interview start time"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Location"
              rules=""
            >
              <b-form-group
                label="Location"
                label-for="location"
              >

                <b-form-input
                  id="location"
                  v-model="location"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Format"
              rules="required"
            >
              <b-form-group
                label="Format"
                label-for="format"
              >
                <template #label>
                  <span>Format<sup style="color: tomato">*</sup></span>
                </template>

                <v-select
                  id="format"
                  v-model="format"
                  :clearable="false"
                  :reduce="val => val.value"
                  :options="locationTypes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Proceed
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BForm, BFormGroup,
  BFormInvalidFeedback, BButton, BModal, BCalendar, BFormTimepicker, BFormInput
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BFormGroup,
    BCalendar,
    BFormInput,
    BFormTimepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isScheduleInterview',
    event: 'update:is-schedule-interview',
  },
  props: {
    isScheduleInterview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,

      format: '',
      location: '',
      interview_time: '',
      interview_date: new Date(),
    }
  },
  computed: {
    disableForm(){
      return false
    },
  },
  methods: {
    disableWeekends(ymd, date){
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
    onCreateOrUpdate(){
      const { interview_date, interview_time, location, format } = this;
      this.$emit('update:is-schedule-interview', false);
      this.$emit("send", { interview_date, interview_time, location, format });
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.interview_date = '';
      this.interview_time = '';
      this.location = '';
      this.format = '';
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>
